// Packages
import { defineStore } from 'pinia';

export const useAlertsStore = defineStore('alerts', () => {
  const quantity = ref(0);

  function setQuantity(newQuantity: number) {
    quantity.value = newQuantity;
  }

  return { setQuantity, quantity }
})